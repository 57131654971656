export default function Greetings() {
  return (
    <div id="hello">
      <h1 className="greetings">
        Hi there
        <span className="name">!</span>
      </h1>
    </div>
  );
}
